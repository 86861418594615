<script>
  export let policy = null;
  //$:console.log(policy);
</script>

{#if !!policy && !!policy.id}
  <data class={policy.type} value={policy.id}>
    <figure>
      {#if policy.photo}
        <img
          alt="amenity"
          src="{policy.photo.url.replace(
            'https://upload.parkingboss.com/files/',
            'https://property-content.imgix.net/'
          )}?auto=compress,format&w=500"
        />
      {:else}
        <img
          class="policy {policy.title
            .toLowerCase()
            .split(/[^a-z]+/gi)
            .join(' ')}"
          alt="amenity"
        />
      {/if}
      {#if policy.pricing}
        {#if policy.pricing.always}
          <data value="always" class="pricing">Always</data>
        {:else}
          <data value="partial" class="pricing">Sometimes</data>
        {/if}
      {/if}
    </figure>
    <h1>{policy.title}</h1>
  </data>
{/if}
