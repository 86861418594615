<script>
  import { onMount } from "svelte";
  import { get, merge, has, size, map, find } from "lodash-es";
  import page from "page";
  import {
    stringToInterval,
    stringToDates,
    deviceTimeAsIfLocal,
    toInterval,
  } from "./datetime";
  import { fetchCreateToken, fetchCreatePermit } from "./api";
  import { account } from "./stores";
  import UnitSelector from "./UnitSelector.svelte";
  import Time from "./Time.svelte";
  import { Stripe } from "./util/stripe";
  import StripePayment from "./components/StripePayment.svelte";

  console.log("stripe=", Stripe);

  export let policy = null;
  export let valid = null;
  export let units = [];

  let element = null;
  let feesElement;
  onMount(() => {
    element && element.scrollIntoView();
  });

  $: if (feesElement) feesElement.scrollIntoView();

  let submittable = false;
  let submitting = false;
  let values = {};
  let form = null;
  let telInput = null;
  let unitSelectorId;
  let payment = null; // hold the payment info
  //$: telInput ? format.apply.apply : null;
  let requestPayment;
  let paymentMethod;
  let paymentToken;
  let paymentError;

  let unit =
    units &&
    get($account, "subject") &&
    find(units, { id: get($account, "subject") }); // try to pull from current account

  $: selectedTime = stringToInterval(valid, deviceTimeAsIfLocal);
  $: values = merge(values, {
    unit: unit ? unit.id : null,
    valid: valid || "",
  });

  $: hasPricing = policy && policy.pricing;
  $: paymentRequired = payment && payment.required;

  $: testIssue = hasPricing && !payment;

  $: requestAgreement = !!get(policy, "agreement.request") && !testIssue;

  //$: console.log(selectedTime);
  //$: console.log("values=", values);

  $: if (unit) payment = null; // clear payment

  async function onSubmit(e) {
    e.preventDefault();
    if (!submittable) return;
    if (submitting) return;

    paymentError = null; // clear

    submitting = true;
    //console.log("submitting", e.target);

    const form = e.target;

    if (requestPayment) {
      var paymentResult = await requestPayment();
      console.log("paymentresult=", paymentResult);
      paymentToken = paymentResult?.token?.id;
      if (!paymentToken) {
        submitting = false;
        return; // done!
      }
    }

    var data = new FormData(form);

    // attempt to pre-create token if all are set
    if (
      get(form, "location.value") &&
      get(form, "unit.value") &&
      get(form, "token.value") &&
      get(form, "policy.value")
    ) {
      // auth - send conflict context
      var auth = await fetchCreateToken(
        get(form, "location.value"),
        get(form, "unit.value"),
        get(form, "token.value"),
        get(form, "policy.value")
      );
      if (!!auth.message) {
        submitting = false;
        alert(auth.message);
        return;
      }

      // hard override the form dat
      if (paymentToken) data.set("payment.source", paymentToken);

      data.append("Authorization", `${auth.type} ${auth.token}`); // add the authorization token
      if (!!data.delete) data.delete("token");
    }

    //console.log("formdata", data);
    var result = await fetchCreatePermit(data);
    submitting = false;

    // handle payment required
    if (result && result.payment) {
      payment = result.payment;
      return;
    }

    var permit = get(result, "permits.item");
    if (permit) return page(`/p/${permit}`);
    //console.log("permit result=", result);
    alert(result.message || "Something went wrong!");
  }

  function onTenant(e) {}

  function arePolicyRequirementsMet(policy, values) {
    if (!policy) return false;
    if (get(policy, "tenant.required", false) && !values.tenant && !values.unit)
      return false;
    if (get(policy, "authentication", false) && !values.token) return false;
    if (get(policy, "name.required", false) && !values.name) return false;
    if (get(policy, "email.required", false) && !values.email) return false;
    if (get(policy, "tel.required", false) && !values.tel) return false;
    return true;
  }

  function onValue(e) {
    if (!e.target.name) return;
    values = merge(values, {
      [e.target.name]: e.target.value,
    });
  }

  $: submittable =
    !!form &&
    !!form.checkValidity() &&
    arePolicyRequirementsMet(policy, values) &&
    (!payment || !payment.required || !!paymentMethod);
</script>

<form
  class="permit"
  on:submit={onSubmit}
  on:input={onValue}
  on:change={onValue}
  novalidate="novalidate"
  bind:this={form}
  class:submitting
>
  <header>
    <!-- <a href="{has(policy, "property.id") ? `/properties/${policy.property.id}` : null}">
            <Property property={get(policy, "property")} />
        </a>
        <Policy policy={policy} /> -->
    <h1>Complete Pass Details</h1>
  </header>
  {#if !!policy}
    <input type="hidden" name="location" value={policy.property.id} />
    <input type="hidden" name="policy" value={policy.subject} />
    <input type="hidden" name="valid" value={values.valid} />

    {#if !!get(policy, "tenant.request", false) || !!get(policy, "authentication", false)}
      <fieldset class="tenant">
        <ul>
          {#if !!get(policy, "tenant.request", false)}
            <li>
              <label for={unitSelectorId}
                >{get(policy, "tenant.title", "")}</label
              >
              {#if unit}
                <input type="hidden" name="unit" value={unit.id} />
              {/if}
              <UnitSelector
                {units}
                bind:id={unitSelectorId}
                bind:selected={unit}
              />
            </li>
          {/if}
          {#if !!get(policy, "authentication", false)}
            <li>
              <label for="permit-auth">Passcode</label><input
                id="permit-auth"
                type="text"
                pattern="[0-9]*"
                inputmode="numeric"
                maxlength="6"
                autocorrect="off"
                autocomplete="off"
                spellcheck="false"
                name="token"
                required
              />
            </li>
          {/if}
        </ul>
      </fieldset>
    {/if}

    {#if !!get(policy, "name.request", false)}
      <fieldset class="people">
        <p>Each person must have a pass—who is this pass for?</p>
        <ul>
          <li>
            <label for="permit-name">Name</label><input
              id="permit-name"
              type="text"
              name="name"
              value=""
              spellcheck="false"
              required={!!get(policy, "name.required", false)}
              autocomplete="name"
            />
          </li>
        </ul>
      </fieldset>
    {:else if !!get(policy, "notes.request", false)}
      <fieldset class="people">
        <p>Who's going to be using this amenity?</p>
        <ul>
          <li>
            <label for="permit-notes">Names</label><input
              id="permit-notes"
              name="notes"
              spellcheck="false"
              placeholder={!!get(policy, "notes.required", false)
                ? ""
                : "optional"}
              required={!!get(policy, "notes.required", true)}
            />
          </li>
        </ul>
      </fieldset>
    {/if}

    {#if !!get(policy, "tel.request", false) || !!get(policy, "email.request", false)}
      <fieldset class="contact">
        <p>Contact info:</p>
        <ul>
          {#if !!get(policy, "tel.request", false)}
            <li>
              <label for="permit-tel">Phone</label><input
                id="permit-tel"
                type="tel"
                name="tel"
                placeholder={!!get(policy, "tel.required", false)
                  ? ""
                  : "optional"}
                required={!!get(policy, "tel.required", false)}
                value=""
                spellcheck="false"
                autocomplete="tel"
                bind:this={telInput}
              />
            </li>
          {/if}
          {#if !!get(policy, "email.request", false)}
            <li>
              <label for="permit-email">Email</label><input
                id="permit-email"
                type="email"
                name="email"
                placeholder={!!get(policy, "email.required", false)
                  ? ""
                  : "optional"}
                required={!!get(policy, "email.required", false)}
                value=""
                spellcheck="false"
                autocomplete="email"
              />
            </li>
          {/if}
        </ul>
      </fieldset>
    {/if}
    {#if payment}
      <ul class="fees" bind:this={feesElement}>
        {#each Object.values(payment.fees.items) as fee}
          <li>
            <h1>{fee.name}</h1>
            <data value={fee.total.value}>{fee.total.display}</data>
          </li>
        {:else}
          <li>
            <h1>Free</h1>
            <data value="0">$0.00</data>
          </li>
        {/each}
        <!-- <li>
                <h1>Total</h1>
                <data value="{payment.total.value}">{payment.total.display}</data>
            </li> -->
      </ul>
      {#if paymentRequired}
        <StripePayment
          bind:method={paymentMethod}
          {payment}
          bind:requestPayment
        />
        <!-- <p>Rules and cancellation policy</p> -->
      {/if}
    {/if}
    {#if requestAgreement}
      <p>
        <input
          id="permit-agreement"
          type="checkbox"
          name="agreement-{policy.version.id || policy.version}"
          value="true"
          required
        /><label for="permit-agreement">{policy.agreement.text}</label>
      </p>
    {/if}
  {/if}
  <footer>
    {#if paymentRequired && paymentMethod && !paymentMethod.card}
      <Time value={valid} />
      <input type="hidden" name="payment.source" value={paymentToken || ""} />
      <button
        type="submit"
        disabled={!submittable}
        class={Object.entries(paymentMethod || {})
          .filter(([k, v]) => v)
          .map(([k, v]) => k)
          .join(" ")}>Continue</button
      >
    {:else if paymentRequired}
      <Time value={valid} />
      <!-- <data class="total" value="{payment.total.value}">{payment.total.display}</data> -->
      <input type="hidden" name="payment.source" value={paymentToken || ""} />
      <button
        type="submit"
        disabled={!submittable}
        class={Object.entries(paymentMethod || {})
          .filter(([k, v]) => v)
          .map(([k, v]) => k)
          .join(" ")}>Pay</button
      >
    {:else if testIssue}
      <Time value={valid} />
      <input type="hidden" name="issue" value="false" />
      <button type="submit" disabled={!submittable}>Continue</button>
    {:else}
      <Time value={valid} />
      <button type="submit" disabled={!submittable}>Get Pass</button>
    {/if}
  </footer>
</form>
