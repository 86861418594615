<script>
  import {
    propertyId,
    policyId,
    permitId,
    permit,
    property,
    properties,
    policies,
    policy,
    units,
    spaces,
    geo,
    view,
    valid,
    permits,
    params,
    account,
  } from "./stores";

  import { merge, get } from "lodash-es";
  import {
    isFuture,
    isPast,
    isWithinInterval,
    format,
    isSameDay,
  } from "date-fns";
  import PolicyDetail from "./PolicyDetail.svelte";
  import PermitDetail from "./PermitDetail.svelte";
  import Property from "./Property.svelte";
  import PropertyDetail from "./PropertyDetail.svelte";
  import Permit from "./Permit.svelte";
  import NewPermit from "./NewPermit.svelte";
  import PropertySearch from "./PropertySearch.svelte";
  import AccountLogin from "./AccountLogin.svelte";

  //let status = null;
  //$: document.documentElement[status ? "setAttribute" : "removeAttribute"]("data-status", status);

  $: document.documentElement[$view ? "setAttribute" : "removeAttribute"](
    "data-view",
    $view
  );
  $: document.documentElement[$propertyId ? "setAttribute" : "removeAttribute"](
    "data-property",
    $propertyId
  );
  $: document.documentElement[$policyId ? "setAttribute" : "removeAttribute"](
    "data-policy",
    $policyId
  );
  $: document.documentElement[$permitId ? "setAttribute" : "removeAttribute"](
    "data-permit",
    $permitId
  );

  let nav = true;
  $: if ($params.nav === "false") nav = false;
</script>

{#if nav}
  <header>
    <nav>
      <ul>
        {#if $propertyId}
          <li>
            <a href="/">Select Location</a>
          </li>
        {/if}
      </ul>
    </nav>
  </header>
{/if}

<main>
  <header>
    {#if $property}
      <Property property={$property} />
    {/if}

    {#if nav}
      <nav>
        <ul>
          {#if $propertyId}
            <li>
              <a href="/properties/{$propertyId}">All Amenities</a>
            </li>
            <li>
              <a href="/properties/{$propertyId}/permits">Your Passes</a>
            </li>
          {/if}
        </ul>
      </nav>
    {/if}
  </header>

  {#if $view == "permit"}
    <section><PermitDetail permit={$permit} /></section>
  {:else if $policyId}
    <PolicyDetail
      policy={$policy}
      date={get($params, "date")}
      selected={get($params, "selected")}
      valid={$valid}
      section={$params.subview || $view}
      permits={$permits &&
        $permits.filter(
          (item) =>
            item &&
            !item.valid.expired &&
            !item.valid.cancelled &&
            ($policyId === item.issued.policy ||
              $policyId === item.issued.issuer)
        )}
    />
  {:else if $view == "property"}
    <PropertyDetail
      property={$property}
      policies={$policies}
      permits={$permits &&
        $permits.filter((item) => !item.valid.expired && !item.valid.cancelled)}
    />
  {:else if $view == "index"}
    <!-- {#if !!$geo}
        <nav>
            <h1>Nearby:</h1>
            <ul class="properties">
                {#each $geo as nearby}
                    <li>
                        <a href="/properties/{nearby.id}">
                            <Property property={nearby} />
                        </a>
                    </li>
            {/each}
            </ul>
        </nav>
    {/if} -->

    <nav class="properties">
      <PropertySearch />
      <h1>Recent</h1>
      <ul class="properties recent" data-count={get($properties, "length", "")}>
        {#if !!$properties}
          {#each $properties as recent}
            <li>
              {#if !!recent}
                <a href="/properties/{recent.id}">
                  <Property property={recent} />
                </a>
              {/if}
            </li>
          {/each}
        {/if}
      </ul>
      {#if !!$geo}
        <h1>Nearby</h1>
        <ul class="properties nearby" data-count={get($geo, "length", "")}>
          {#each $geo as nearby}
            <li>
              <a href="/properties/{nearby.id}">
                <Property property={nearby} />
              </a>
            </li>
          {/each}
        </ul>
      {/if}
    </nav>
  {/if}
  {#if $property && $view == "permits"}
    {#if $account}
      <nav class="permits">
        <header>
          <h1>
            <data
              class="{$account.type} id {[$account.format, $account.size]
                .filter((i) => !!i)
                .join(' ')}"
              value={$account.id}>{$account.display}</data
            >
          </h1>
        </header>
        <h1>Your Passes</h1>
        {#if !$permits}
          <ul class="permits"></ul>
        {:else}
          <ul class="permits" data-count={get($permits, "length", "")}>
            {#each $permits as permit}
              <li>
                <a
                  href={!!permit && permit.id ? `/p/${permit.id}` : null}
                  class:selected={permit &&
                    permit.id &&
                    permit.id === $permitId}
                >
                  <Permit {permit} />
                </a>
              </li>
            {/each}
          </ul>
        {/if}
      </nav>
    {:else}
      <AccountLogin property={$property} units={$units || []} />
    {/if}
  {/if}
  {#if $property && $policyId}
    <nav class="permits">
      {#if get($policy, "issue.enabled")}
        {#if !$permits}
          <ul class="permits"></ul>
        {:else}
          <ul class="permits" data-count={get($permits, "length", "")}>
            {#each $permits as permit}
              <li>
                <a
                  href={!!permit && permit.id ? `/p/${permit.id}` : null}
                  class:selected={permit &&
                    permit.id &&
                    permit.id === $permitId}
                >
                  <Permit {permit} />
                </a>
              </li>
            {/each}
          </ul>
        {/if}
      {/if}
    </nav>
  {/if}
  <footer>
    {#if $property && $property.logo}
      <img
        alt="logo"
        src="{$property.logo.url.replace(
          'https://upload.parkingboss.com/files/',
          'https://property-content.imgix.net/'
        )}?auto=compress,format&w=200&dpr=2&fit=max"
      />
    {/if}
  </footer>
</main>
<!-- {#if !!$policies && "agenda" === $view}
        <section class="agendas">
            <ul class="agendas">
            {#each $policies as policy}
                <li>
                    <PolicyDetail permit={false} policy={policy}/>
                </li>
           {/each}
            </ul>
        </section>
    {/if} -->

<!-- {#if !!$units  && "spaces" === $view}
    <nav>
        <ul>
            {#each $units as item}
                <data class="{item.type} id {[ item.format, item.size ].filter(i => !!i).join(" ")}" value="{item.id}">{item.display}</data>
            {/each}
        </ul>
    </nav>
    {/if}

    {#if !!$spaces && "spaces" === $view}
    <nav>
        <ul>
            {#each $spaces as item}
                <data class="{item.type} id {[ item.format, item.size ].filter(i => !!i).join(" ")}" value="{item.id}">{item.display}</data>
            {/each}
        </ul>
    </nav>
    {/if} -->
